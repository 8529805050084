<!--
 * @Description: 问卷链接失效提示页
 * @version: 
 * @Author: PSG
 * @Date: 2021-05-07 14:49:17
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-11 15:01:30
-->
<template>
  <Header
    v-if="isPc"
    :isShowLogo="true"
    :isForbidLogoClick="true"
  />
  <!-- 电脑端 -->
  <div class="invaild" v-if="isPc">
   <div class="content">
     <div class="wrapper">
      <img class='icon' src="@/assets/images/invalid.png" alt="">
      <p class="text">问卷链接失效</p>
      <p class="tips">该问卷已停止派发，问卷链接已失效。<br/>感谢您的关注！</p>
    </div>
   </div>
  </div>
  <!-- 手机端 -->
  <div class="mobile-invaild" v-else>
    <div class="wrapper">
      <img class='icon' src="@/assets/images/invalid.png" alt="">
      <p class="text">问卷链接失效</p>
      <p class="tips">该问卷已停止派发，问卷链接已失效。<br/>感谢您的关注！</p>
    </div>
  </div>
</template>

<script>
import Header from '@/components/common/Header'
import { getTerminalType } from '@/utils/userAgent.js'

export default {
  components: {
    Header,
  },
  /**
   * 程序入口
   */
  setup () {
    const isPc = getTerminalType() === 'PC' ? true : false
    return {
      isPc,
    }
  }
}
</script>

<style lang='less' scoped>
// 电脑端
.invaild {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: @backgroundColor;
  padding: 20px;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    min-height: 600px;
    background-color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    .icon {
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      text-align: center;
    }
    .text {
      margin-top: 16px;
      color: #7F7F88;
      font-size: 26px;
      text-align: center;
    }
    .tips {
      margin-top: 16px;
      color: #7F7F88;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      padding: 0 50px;
    }
  }
}
// 手机端
.mobile-invaild {
  .wrapper {
    margin-top: 120px;
    .icon {
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto;
      text-align: center;
    }
    .text {
      margin-top: 16px;
      color: #7F7F88;
      font-size: 26px;
      text-align: center;
    }
    .tips {
      margin-top: 16px;
      color: #7F7F88;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      padding: 0 50px;
    }
  }
}
</style>